import { max, toInteger } from 'lodash';
import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, InputStepper, Popup, Row, Loading, H4, Text, colors, Button, Link, themeOptions } from '@commonsku/styles';
import {
  selectStripeCalculatedQuantity,
  selectStripeError,
  selectStripeInfo,
  selectStripeLoading,
  selectStripePendingChange,
  selectStripePlanInterval,
  selectStripeTempMessage,
  setError as setStripeError,
  updateStripeQuantity,
  updateTemp
} from '../../../redux/stripe';

const UpdateLicensePopup = (props) => {
  const {
    onClose,
  } = props;

  const valChanged = useRef(false);
  const dispatch = useDispatch();
  const stripeInfo = useSelector(selectStripeInfo);
  const licensesQty = useSelector(selectStripeCalculatedQuantity);
  const pendingChange = useSelector(selectStripePendingChange);
  const stripeLoading = useSelector(selectStripeLoading);
  const stripeError = useSelector(selectStripeError);
  const message = useSelector(selectStripeTempMessage);
  const stripeInterval = useSelector(selectStripePlanInterval);
  const annualContract = stripeInterval === 'year';
  const [quantity, setQuantity] = useState(licensesQty);
  const [error, setError] = useState('');

  const minQuantity = annualContract ? licensesQty : max([
    2,
    toInteger(stripeInfo?.activeUsers || 2)
  ]);

  const lowerLimitWarning = annualContract ?
    <>You cannot lower your number of licenses on an annual contract.</> : (
      quantity === 2 ?
    <>The minimum number of licenses is 2.</> :
    <>You must <Link style={{ fontFamily: themeOptions.fontFamilies.regular, }} as="a" href="#" target="_blank">deactivate a user</Link> to lower your license count.</>
  );

  const setMessage = useCallback(
    (value='') => dispatch(updateTemp({ message: value })),
    [dispatch]
  );

  useEffect(() => {
    setQuantity(s => s === licensesQty ? s : licensesQty);
  }, [licensesQty]);

  const onChangeQuantity = useCallback((value) => {
    if (value === null || value === undefined || +value === 0) { return; }
    valChanged.current = true;
    setQuantity(toInteger(value));
    dispatch(setStripeError(''));
    setError('');
  }, [dispatch, quantity]);

  const submitChange = () => {
    if (quantity < minQuantity) { return; }
    dispatch(updateStripeQuantity(quantity));
    valChanged.current = false;
  };

  const handleClose = () => {
    setQuantity(licensesQty);
    dispatch(setStripeError(''));
    setError('');
    setMessage('');
    onClose();
  };

  return <Popup
    width="580px"
    height="auto"
    header={
      <Row justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
        <H4>Change number of licenses</H4>
      </Row>
    }
    onClose={handleClose}
    id='update-license-popup'
  >
    <Row>
      <Col xs style={{ ...(!stripeLoading ? { display: 'none' } : {}), textAlign: 'center' }} id='license-popup-loading'>
        <Loading />
      </Col>
      <Col xs style={{ ...(stripeLoading ? { display: 'none' } : {}), }}>
        {!!stripeError && <Text as="p" style={{ color: colors.errors.main }} id='licenses-stripe-error'>{stripeError}</Text>}
        {!!error && <Text as="p" style={{ color: colors.errors.main }} id='licenses-error'>{error}</Text>}
      </Col>
      <Col xs sm={4} style={{ ...(stripeLoading ? { display: 'none' } : {}), }}>
        <Text style={{ fontSize: 16, color: colors.neutrals['90'] }} id='licenses-input-label'>Licenses</Text>
          {(quantity !== null && quantity !== 0) && <InputStepper
            initialValue={parseInt(quantity)}
            min={parseInt(minQuantity)}
            onChange={onChangeQuantity}
            disabled={stripeLoading}
            id='licenses-input'
            delayChangeTimeout={100}
            holdIncrement={false}
            holdDecrement={false}
          />}
      </Col>
      <Col xs style={{ ...(stripeLoading ? { display: 'none' } : {}), paddingTop: 10, }}>
        {pendingChange ? <span
          id='licenses-pending-changes'
          style={{
            color: colors.neutrals['90'],
            fontFamily: themeOptions.fontFamilies.regular,
            display: 'block'
          }}
        >
          You have {stripeInfo.quantity} licenses, changing to {pendingChange.quantity} on {moment(pendingChange.charge_date).tz('UTC').format('MMM DD, YYYY')}.
        </span> : null}
        <span id='licenses-deactivate-user-text' style={{
          ...(quantity === minQuantity && minQuantity > 0
              ? { color: colors.neutrals['90'], fontFamily: themeOptions.fontFamilies.regular, }
              : {display: 'none'})
        }}>
          {lowerLimitWarning}
        </span>
      </Col>
      <Col xs style={{ ...(stripeLoading ? { display: 'none' } : {}), }}>
        <p id='licenses-message' style={{
          color: colors.neutrals['90'],
          fontFamily: themeOptions.fontFamilies.regular,
        }}>{message}</p>
      </Col>
      <Col padded xs sm={6} end={1} style={{ paddingLeft: 0 }}>
        <Button
          variant='secondary'
          onClick={handleClose}
          style={{ width: '100%', }}
          id='close-btn'
        >Close</Button>
      </Col>
      <Col padded xs sm={6}>
        <Button
          variant='primary'
          onClick={submitChange}
          disabled={stripeLoading || !!stripeError || (quantity === licensesQty)}
          style={{ width: '100%' }}
          id='change-licenses-btn'
        >Change Licenses</Button>
      </Col>
    </Row>
  </Popup>;
};

export default UpdateLicensePopup;
